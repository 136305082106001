body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'HouschkaRoundedAlt';
  src: url('./fonts/Houschka-Rounded-Alt-Light-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Houschka';
  src: url('./fonts/HouschkaLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HouschkaRoundedAlt';
  src: url('./fonts/HouschkaRoundedAlt-Regular6.ttf') format('truetype');
  font-weight: 500;
}

:root {
  --desktop-center-width: 500px;
}

h1, h2, h3, h4, h5, h6, a, p, span, li {
  font-family: HouschkaRoundedAltSix, HouschkaRoundedAlt;
  color: #22034F;
}
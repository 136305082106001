.cd-photo {
  border-radius: 100px;
  overflow: hidden;
}

.cd-photo.editable {
  cursor: pointer;
  position: relative;
}

.cd-photo > img {
  width: 100%;
  height: 100%;
}

/* upload */
.upload-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #50505088;
  opacity: 0.001;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-wrapper:hover  {
  opacity: 1.0;
}

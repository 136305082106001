.measure-breakdown {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.measure-breakdown > h1 {
  font-size: 20px;
  margin: 0 0 6px;
}

.measure-bd-section {
  margin: 6px 0 6px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.measure-bd-section > .svg-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0 12px;
}

.measure-bd-section > .svg-wrap > svg {
  width: 60px;
  height: auto;
}

.measure-bd-section > h2, .measure-bd-section > p,
  .measure-bd-section > ul > li, .measure-bd-section > ol > li {
  font-size: 16px;
  margin: 0 0 6px;
}

.measure-breakdown > div.hr {
  width: 100%;
  height: 1px;
  opacity: 0.12;
  background-color: #453DB7;
}

.supporters-opponents {
  display: flex;
}

.measure-bd-section.sources {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.measure-source {
  margin: 6px 0 0px;
  opacity: 0.6;
}
.measure-source > a {
  word-break: break-all;
}

@media(max-width: 480px) {
  .supporters-opponents {
    flex-direction: column;
  }
}

@media(min-width: 480px) {
  .measure-breakdown {
    padding: 24px;
  }
  .supporters-opponents {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .supporters-opponents > .measure-bd-section {
    max-width: 48%;
  }

  .measure-bd-section > .svg-wrap > svg {
    width: 40px;
  }
}

.input-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.input-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.input-content-wrapper > span, .input-content-wrapper > p {
  font-size: 16px;
  text-align: left;
  margin: 0;
}

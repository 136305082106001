.issue-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 2px 0 8px;
  width: 100%;
}

/* top priority */
.top-priority-marker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
}

.top-priority-marker > svg {
  height: 18px;
  width: auto;
  margin-right: 6px;
}

.top-priority-marker > span {
  letter-spacing: 0.08em;
  color: #000000;
  margin-top: 4px;
  opacity: 0.5;
  font-size: 14px;
  font-weight: bold;
}

/* header */

.issue-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2px 0 4px;
  width: 100%;
}

.issue-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.issue-title > span {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 0;
}

.issue-wrapper > div > p, li {
  font-size: 14px;
}

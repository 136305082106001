.search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 800px;
  height: 50px;
  margin-bottom: 40px;
}

.search-bar > div {
  margin: 0 10px 0;
}

.measure-as-on-ballot {
  padding: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.measure-as-on-ballot > h2 {
  font-size: 16px;
  margin: 0 0;
}

.measure-as-on-ballot > p.ballot-text {
  opacity: 0.8;
}

.measure-as-on-ballot > p.act-numbers {
  opacity: 0.6;
  font-size: 14px;
}

.measure-as-on-ballot > * {
  opacity: 0.8;
  font-size: 16px;
}

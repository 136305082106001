.links-list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.website-link-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.website-link-wrapper > svg {
  margin-right: 4px;
}
.website-link-wrapper > a {
  color: #22034F;
  opacity: 0.7;
  font-size: 14px;
}

.social-links-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.8;
  height: 40px;
  margin-right: calc(-1 * (40px - 24px) / 2);
}

.social-links-wrapper > a {
  height: 40px;
  width: 40px;
}

.social-links-wrapper > a > svg {
  height: 24px;
  width: 24px;
  margin: calc((40px - 24px) / 2);
}


/* .candidate-link {
  height: 40px;
  margin: 5px 0 5px;
  width: 100%;
  background: #F0EFF9;
  border-radius: 12px;
  display: flex; flex-direction: row; justify-content: center; align-items: center;
  color: #453DB7;
}

.candidate-link > span {
  font-size: 16px;
  text-decoration: underline;
}

.candidate-link > svg {
  margin-left: 8px;
  height: 12px;
  width: 12px;
} */
